<template>
  <el-dialog
    v-model="showAddJournalDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-add-journal-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-add-journal-dialog-header__title">
        {{ props.model === 'add' ? t('title.addJournal') : t('title.editJournal') }}
      </h4>
    </template>
    <el-scrollbar v-loading="previewLoading" view-class="elv-add-journal-content" :max-height="`calc(100vh - 200px)`">
      <div
        v-if="!isEmpty(props.currentData) && (props.model === 'add' || props.currentData?.transactionList?.length)"
        class="elv-add-journal-transaction-info"
      >
        <template v-if="props.currentData?.source?.deletedAt && props.model === 'edit'">
          <div class="elv-add-journal-transaction-info__type">
            <SvgIcon name="sources-delete" width="16" height="16" fill="#AAAFB6" />
          </div>
          <div class="elv-add-journal-transaction-info__deleted">{{ t('message.transactionDeletedInfo') }}</div>
        </template>
        <template v-else>
          <div class="elv-add-journal-transaction-info__type">
            <SvgIcon :name="transactionTypeIcon" width="16" height="16" />
          </div>
          <div class="elv-add-journal-transaction-info__identity">
            <div class="elv-add-journal-transaction-info__identity-top">
              {{
                dayjs(props.currentData?.datetime)
                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                  .format(props.currentData.format || 'YYYY/MM/DD HH:mm')
              }}
            </div>

            <div
              v-if="props.currentData?.txHash || props.currentData?.transactionList?.[0].txHash"
              class="elv-add-journal-transaction-info__identity-bottom"
              @click="onClickedCopy(props.currentData?.txHash || props.currentData?.transactionList?.[0].txHash)"
            >
              <div
                class="elv-add-journal-transaction-info__identity-bottom__txHash"
                :data-clipboard-text="props.currentData?.txHash || props.currentData?.transactionList?.[0].txHash"
              >
                {{ formatInputAddress(props.currentData?.txHash || props.currentData?.transactionList?.[0].txHash) }}
              </div>
              <img src="@/assets/img/copy.png" alt="" class="elv-add-journal-transaction-info__identity-bottom__copy" />
            </div>
          </div>
          <div class="elv-add-journal-transaction-info__amount">
            <template v-if="props.currentData?.txHash || props.currentData?.transactionId">
              {{
                Number(props.currentData?.amount) < 10 ** -6
                  ? '0'
                  : formatNumberToSignificantDigits(Number(props.currentData?.amount), 2, '', false)
              }}
              {{ props.currentData?.currency }}
            </template>
            <template v-else>
              {{
                Number(props.currentData?.transactionList?.[0]?.amount) < 10 ** -6
                  ? '0'
                  : formatNumberToSignificantDigits(
                      Number(props.currentData?.transactionList?.[0]?.amount),
                      2,
                      '',
                      false
                    )
              }}
              {{ props.currentData?.transactionList?.[0]?.currency }}
            </template>
          </div>
        </template>
      </div>

      <el-form ref="journalFormRef" :model="journalForm" label-position="top" :rules="rules">
        <div class="elv-transactions-add-journal-form-item__multiple">
          <el-form-item :label="t('report.journalType')" prop="journalTypeId">
            <el-popover
              ref="journalTypeSelectRef"
              placement="bottom-start"
              trigger="click"
              popper-class="elv-add-journal-type-popper"
              :show-arrow="true"
              :offset="0.2"
              width="650px"
              :disabled="isGeneralJournalType && !isEmpty(props.currentData) && props.model !== 'edit'"
              @before-enter="onCheckSelectArrow(true)"
              @before-leave="onCheckSelectArrow(false)"
            >
              <template #reference>
                <div
                  class="elv-add-journal-type-select"
                  :class="{
                    'is-disabled': isGeneralJournalType && !isEmpty(props.currentData) && props.model !== 'edit',
                    'is-short': isGeneralJournalType
                  }"
                >
                  <div v-if="journalForm.journalTypeId && currentJournalTypeData?.name">
                    <span>{{
                      locale === 'en'
                        ? currentJournalTypeData?.name
                        : currentJournalTypeData?.nameCN || currentJournalTypeData?.name
                    }}</span>
                  </div>
                  <p v-else class="is-placeholder">{{ t('common.select') }} ...</p>
                  <SvgIcon name="events-arrow" width="16" height="16" :style="{ transform: arrowRotate }" />
                </div>
              </template>
              <JournalTypeOverlayDropdown
                v-model="journalForm.journalTypeId"
                :show-general-journal="true"
                :drop-down-data="props.journalTypeList"
                @onChangeJournalTypeSelect="onChangeJournalTypeSelect"
                @onCreateGeneralJournal="onCreateGeneralJournal"
              />
            </el-popover>
          </el-form-item>
          <el-form-item v-if="isGeneralJournalType" :label="t('common.activity')" prop="journalTypeGroup">
            <el-select v-model="journalForm.journalTypeGroup" class="is-shorter">
              <el-option
                v-for="item in reportTransactionType"
                :key="item.value"
                :label="transformI18n(item.label)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="elv-transactions-add-journal-form-item__multiple">
          <el-form-item :label="t('common.timezone')" prop="timezone">
            <el-select
              v-model="journalForm.timezone"
              filterable
              :disabled="!isEmpty(props.currentData)"
              default-first-option
              :placeholder="t('common.select')"
              class="is-shorter"
              :suffix-icon="SelectSuffixIcon"
              @change="onChangeDate"
            >
              <el-option
                v-for="(item, index) in timezoneList"
                :key="index"
                :label="`${item.area} (${item.timezone})`"
                :value="item.area"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="t('common.dateTime')" prop="datetimeStr">
            <el-date-picker
              ref="datePickerStartPopoverRef"
              v-model="journalForm.datetimeStr"
              popper-class="elv-add-journal-date-popper"
              type="date"
              :disabled="!isEmpty(props.currentData)"
              :placeholder="t('common.select')"
              value-format="YYYY-MM-DD HH:mm"
              format="YYYY/MM/DD HH:mm"
              :clearable="false"
              class="is-shorter"
              :disabled-date="disabledDate"
              :prefix-icon="DatetimeIcon"
              @change="onChangeDate"
            />
          </el-form-item>

          <el-form-item :label="t('report.referenceNo')">
            <el-input
              v-model="journalForm.referenceNo"
              :disabled="!isEmpty(props.currentData)"
              class="is-shorter"
              :placeholder="t('placeholder.txHashOrOrderId')"
          /></el-form-item>
        </div>

        <div v-if="journalForm.journalTypeId" class="elv-transactions-add-journal-form-item__entry-list">
          <div class="elv-transactions-add-journal-form-item-title entryLines">{{ t('title.entryLines') }}</div>
          <div
            v-for="(item, index) in journalForm.entryLines"
            :key="item.index"
            class="elv-transactions-add-journal-form-entry-item"
            :class="[item.balanceType === 'Dr' ? 'Dr' : 'Cr']"
          >
            <div
              class="elv-transactions-add-journal-form-entry-item__title"
              :class="[isGeneralJournalType ? 'general-journal' : 'special']"
            >
              <template v-if="isGeneralJournalType">
                <el-select
                  v-model="item.balanceType"
                  :disabled="!isEmpty(hasGstType)"
                  class="elv-transactions-add-journal-form-entry-item__title-balance"
                >
                  <el-option label="Dr" value="Dr" />
                  <el-option label="Cr" value="Cr" />
                </el-select>
                <el-form-item label="" prop="journalForm.entryLines[index].chartOfAccountId">
                  <ChartOfAccountOverlayDropdown
                    v-model="item.chartOfAccountId"
                    :disabled="disabledChartOrAccount(item)"
                    @onChangeChartOfAccount="onChangeChartOfAccount(index)"
                  />
                </el-form-item>
                <div class="elv-transactions-add-journal-form-entry-item__delete">
                  <SvgIcon
                    name="sources-delete"
                    width="16"
                    height="16"
                    fill="#AAAFB6"
                    :class="{ 'is-disabled': !isEmpty(hasGstType) }"
                    @click="onDeleteEntry(item.index)"
                  />
                </div>
              </template>

              <div
                v-else
                class="elv-transactions-add-journal-form-entry-item__title-coa"
                :class="{ 'is-Empty': !item.chartOfAccountId }"
              >
                <div>
                  <span class="elv-transactions-add-journal-form-entry-item__title-balance">{{
                    item.balanceType
                  }}</span>
                  <div v-loading="accountLoading[index]">
                    {{
                      item.chartOfAccountId
                        ? locale === 'en'
                          ? currentChartOfAccount(item.chartOfAccountId).value?.name
                          : currentChartOfAccount(item.chartOfAccountId).value?.nameCN ||
                            currentChartOfAccount(item.chartOfAccountId).value?.name
                        : t('message.emptyChartOfCountInfo')
                    }}
                  </div>
                </div>
                <p
                  v-if="
                    showEntryAccount(item, item.index)?.type === 'TREASURY_ACCOUNT' &&
                    showEntryAccount(item, item.index)?.show &&
                    props.model !== 'edit'
                  "
                  @click="onResetEntry(item.index)"
                >
                  <SvgIcon name="reset" width="14" height="14" fill="#838D95" />{{ t('button.reset') }}
                </p>
              </div>
            </div>

            <div
              class="elv-transactions-add-journal-form-entry-item__multiple"
              :class="[isGeneralJournalType ? 'general-journal' : 'special']"
            >
              <el-form-item
                :label="t('common.currency')"
                required
                :prop="`entryLines.${item.index}.currency`"
                :rules="{
                  required: true,
                  validator: validatorCurrency,
                  trigger: 'change'
                }"
              >
                <!-- (item.balanceType === 'Dr' && (item.index + 1) % 2 === 0) ||
                    (item.balanceType === 'Cr' && (item.index + 1) % 2 !== 0) -->
                <el-select
                  v-model="item.currency"
                  :teleported="false"
                  filterable
                  remote
                  clearable
                  remote-show-suffix
                  reserve-keyword
                  :loading="currencyLoading"
                  :disabled="
                    (props.currentData?.transactionIds?.length &&
                      props.model === 'edit' &&
                      !isGeneralJournalType &&
                      !isCompoundEntry) ||
                    currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                    currentChartOfAccount(item.chartOfAccountId).value?.slug === 'gst_input' ||
                    currentChartOfAccount(item.chartOfAccountId).value?.slug === 'gst_output'
                  "
                  :remote-method="remoteCurrencyMethod(item.index)"
                  class="is-shorter-entry"
                  :placeholder="t('placeholder.inputCurrencyCode')"
                  @change="onChangeCurrency(index)"
                >
                  <el-option
                    v-for="currency in currenciesData"
                    :key="currency.symbol"
                    :label="currency.showSymbol"
                    :value="currency.symbol"
                    ><img :src="currency?.logo ?? defaultImg" :alt="currency.name" :onerror="useDefaultImage" />{{
                      currency.showSymbol
                    }}</el-option
                  >
                  <template v-if="item.currency" #prefix>
                    <img
                      :src="selectedCurrency(item.currency).value?.logo ?? defaultImg"
                      :alt="selectedCurrency(item.currency).value?.name"
                      :onerror="useDefaultImage"
                    />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="t('common.amount')"
                required
                :prop="`entryLines.${item.index}.amount`"
                :class="{ 'is-sale-disabled': journalForm.brokerInventoryType === 'SALE' && item.balanceType === 'Dr' }"
                :rules="{
                  required: true,
                  validator: validatorAmount(item),
                  trigger: 'blur'
                }"
                class="elv-transactions-add-journal-form-entry-item__multiple-amountFC"
              >
                <!-- :disabled="currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING'" -->
                <el-input
                  v-model="item.amount"
                  :disabled="disabledAmount(item)"
                  class="is-shorter-entry"
                  :formatter="(value: string) => formatNumberWithCommas(value)"
                  :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
                  @input="onChangeAmount(index)"
                />
                <el-tooltip
                  v-if="item.currency !== entityDetail?.defaultCurrency && journalForm.brokerInventoryType !== 'SALE'"
                  :show-arrow="true"
                  effect="dark"
                  placement="top"
                  width="190"
                  popper-class="elv-add-journal-amount-tips"
                  overlay-classname="elv-report-table-tips"
                  :show-after="500"
                >
                  <div class="elv-add-journal-entry-item__multiple-amountFC-content">
                    <!-- v-show="item?.useManualPrice" -->
                    <div
                      v-show="!journalForm.entryLines[index]?.useManualPrice"
                      v-loading="amountFCLoading[item.index]"
                      class="elv-add-journal-entry-item__multiple-amountFC-auto_calculate"
                      @click="onSwitchAmountFCManuallyCalculate(item)"
                    >
                      {{
                        item.amountFC !== '' && item.currency !== ''
                          ? fieldValueFormat(
                              Number(item.amountFC),
                              {
                                price: true,
                                precision: 2,
                                symbol: `${
                                  entityDetail?.underlyingCurrency?.sign ||
                                  `${entityDetail?.underlyingCurrency?.showSymbol} `
                                }`
                              },
                              'NUMBER'
                            )
                          : '-'
                      }}
                    </div>
                    <!-- v-show="!item?.useManualPrice" -->
                    <el-input
                      v-show="journalForm.entryLines[index]?.useManualPrice"
                      v-model="journalForm.entryLines[index].amountFC"
                      class="elv-add-journal-entry-item__multiple-amountFC-manually"
                      :disabled="disabledAmount(item)"
                      placeholder="-"
                      :formatter="(value: string) => `${currencySign} ${formatNumberWithCommas(value)}`"
                      :parser="
                        (value: string) => {
                          const regex = new RegExp(`\\${currencySign}\\s?|,`, 'g')
                          return value.includes(currencySign) ? value.replace(regex, '') : value
                        }
                      "
                      @input="onChangeManuallyAmountFC"
                    />
                  </div>
                  <template #content>
                    {{
                      journalForm.entryLines[index]?.useManualPrice
                        ? t('message.switchToAutoCalculateAmountFC')
                        : t('message.manuallyEnterFunctionalCurrencyAmountTip')
                    }}
                    <ElvButton
                      v-show="journalForm.entryLines[index]?.useManualPrice"
                      type="primary"
                      round
                      height="30px"
                      width="170px"
                      @click="onSwitchAmountFCAutoCalculate(item)"
                      >{{ t('button.autoCalculate') }}</ElvButton
                    >
                  </template>
                </el-tooltip>
              </el-form-item>
              <template v-if="showEntryAccount(item, index)?.show">
                <template
                  v-if="
                    showEntryAccount(item, item.index)?.type === 'TREASURY_ACCOUNT' &&
                    (!journalForm.entryLines[index].chartOfAccountId ||
                      (journalForm.entryLines[index].chartOfAccountId &&
                        !currentChartOfAccount(journalForm.entryLines[index].chartOfAccountId).value?.auxiliaryTypes
                          ?.length)) &&
                    journalForm.entryLines[index]?.auxiliaryValueList?.[0]?.auxiliaryTypeId
                  "
                >
                  <el-form-item
                    :label="`${t('report.auxiliaryCode')} / ${t('common.account')}`"
                    :prop="`entryLines.${item.index}.auxiliaryValueList.${0}.entityAccountId`"
                    :rules="{
                      required: true,
                      validator: validatorAccount(item),
                      trigger: 'blur'
                    }"
                  >
                    <ElvSelect
                      v-model="journalForm.entryLines[index].auxiliaryValueList[0].entityAccountId"
                      clearable
                      class="is-shorter-entry"
                      width="206px"
                      :placeholder="placeholderText"
                      :disabled="
                        props.model === 'edit' && !isGeneralJournalType && props.currentData?.transactionIds?.length > 0
                      "
                      :options="accountNormalOptions"
                      :collapse-list="accountDeletedOptions"
                      @change="onChangeAccount(index)"
                    >
                      <template #emptyContent>
                        <div class="elv-transactions-add-journal-form-account-item__empty">
                          <svgIcon name="zoom-area" width="24" height="24" />
                          <p>{{ t('message.noAccountFound') }}</p>
                          <router-link :to="{ name: 'entity-accounts-treasury' }">{{
                            t('button.goToAddAccount')
                          }}</router-link>
                        </div>
                      </template>
                    </ElvSelect>
                  </el-form-item>
                </template>
                <el-form-item
                  v-for="(auxiliaryValue, i) in currentChartOfAccount(item.chartOfAccountId).value?.auxiliaryTypes"
                  v-else
                  :key="i"
                  :label="`${t('report.auxiliaryCode')} / ${auxiliaryValue?.name}`"
                >
                  <ElvSelect
                    v-if="auxiliaryValue?.type === 'ENTITY_ACCOUNT'"
                    v-model="item.auxiliaryValueList[i].entityAccountId"
                    clearable
                    class="is-shorter-entry"
                    width="206px"
                    :placeholder="placeholderText"
                    :disabled="
                      props.model === 'edit' &&
                      !isGeneralJournalType &&
                      props.currentData?.transactionIds?.length > 0 &&
                      !isCompoundEntry
                    "
                    :options="accountNormalOptions"
                    :collapse-list="accountDeletedOptions"
                    @change="onChangeAccount(index)"
                  >
                    <template #emptyContent>
                      <div class="elv-transactions-add-journal-form-account-item__empty">
                        <svgIcon name="zoom-area" width="24" height="24" />
                        <p>{{ t('message.noAccountFound') }}</p>
                        <router-link :to="{ name: 'entity-accounts-treasury' }">{{
                          t('button.goToAddAccount')
                        }}</router-link>
                      </div>
                    </template>
                  </ElvSelect>
                  <el-select
                    v-else-if="auxiliaryValue?.type === 'COUNTERPARTY'"
                    v-model="item.auxiliaryValueList[i].counterpartyId"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    class="is-shorter-entry"
                    :loading="searchLoading"
                    :remote-method="remoteCounterpartyMethod"
                    :placeholder="t('placeholder.enterNameForSearch')"
                    popper-class="elv-counterparty-addTo-dialog-popper"
                  >
                    <el-option
                      v-for="counterparty in counterpartyOptions"
                      :key="counterparty.counterpartyId"
                      :label="counterparty.name"
                      :value="counterparty.counterpartyId"
                    />
                  </el-select>

                  <el-select
                    v-else-if="auxiliaryValue?.type === 'ITEM'"
                    v-model="item.auxiliaryValueList[i].auxiliaryItemId"
                    filterable
                    remote
                    clearable
                    class="is-shorter-entry"
                    :placeholder="`${t('common.select')}...`"
                    popper-class="elv-counterparty-addTo-dialog-popper"
                  >
                    <el-option
                      v-for="auxiliaryItem in auxiliaryValue?.auxiliaryItems"
                      :key="auxiliaryItem.auxiliaryItemId"
                      :label="auxiliaryItem.value"
                      :value="auxiliaryItem.auxiliaryItemId"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </div>
            <div v-if="enableGst" class="elv-transactions-add-journal-form-entry-item__multiple">
              <!-- Special Journal-->
              <template
                v-if="
                  !isGeneralJournalType &&
                  item.gstType !== 'NA' &&
                  item.gstType !== 'NONE' &&
                  item.gstType !== '' &&
                  journalForm.entryLines.find((i: any) => i.gstType !== 'NA')?.balanceType === item.balanceType &&
                  (((item.index + 1) % 2 !== 0 && journalForm.entryLines?.length !== 2) ||
                    journalForm.entryLines?.length === 2)
                "
              >
                <div class="elv-transactions-add-journal-form-entry-item__GSTRate">
                  {{ t('title.GSTRate') }}:
                  {{ transformI18n(find(journalTypeGstOptions, { value: item.gstType })?.label)
                  }}<span v-if="item.gstType !== 'EXEMPT'"
                    >, {{ item.gstType !== 'ZERO' ? `${Number(item.gstRatio)}%` : '0%' }}</span
                  >
                </div>
              </template>

              <!-- General Journal -->
              <template
                v-if="
                  isGeneralJournalType &&
                  item.chartOfAccountId !== '' &&
                  showGSTSelect(item.chartOfAccountId).value &&
                  item.gstType !== 'NA'
                "
              >
                <el-form-item
                  :label="t('title.GSTRate')"
                  class="elv-transactions-add-journal-form-entry-item__multiple-type"
                >
                  <el-select
                    v-model="item.gstType"
                    :teleported="false"
                    :disabled="disabledGstType(item).value"
                    :popper-append-to-body="false"
                    @change="onChangeGSTRateType(item.index)"
                  >
                    <el-option
                      v-for="i in journalTypeGstOptions"
                      :key="i.value"
                      :label="transformI18n(i?.label)"
                      :value="i?.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="item.gstType !== 'NONE' && item.gstType !== 'EXEMPT'"
                  label="   "
                  prop="amount"
                  class="elv-transactions-add-journal-form-entry-item__multiple-percent"
                >
                  <el-input-number
                    v-model="item.gstRatio"
                    :disabled="item.gstType === 'ZERO'"
                    :precision="0"
                    :max="100"
                    :controls="false"
                    class="is-shorter-entry"
                    @input="onChangeGSTRate(item.index)"
                  />
                  <div class="symbol">%</div>
                </el-form-item>
              </template>
            </div>
          </div>
          <el-dropdown
            v-if="isGeneralJournalType"
            trigger="click"
            placement="bottom-start"
            :show-arrow="false"
            :teleported="false"
            popper-class="elv-add-journal-type__dropdown-popper"
          >
            <elv-button
              height="32"
              width="110"
              class="elv-add-journal-type-form-item__entry-add"
              :disabled="!isEmpty(hasGstType)"
            >
              <SvgIcon name="add-default" height="16" width="16" fill="#1753EB" />
              {{ t('button.addLine') }}
            </elv-button>
            <template #dropdown>
              <el-dropdown-menu class="elv-add-journal-type__dropdown elv-language" size="large">
                <el-dropdown-item class="elv-add-journal-type__dropdown--item" @click="onAddEntry('Dr')"
                  >Dr</el-dropdown-item
                >
                <el-dropdown-item class="elv-add-journal-type__dropdown--item" @click="onAddEntry('Cr')"
                  >Cr</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <div
            v-if="journalForm.brokerInventoryType !== 'SALE'"
            class="elv-transactions-add-journal-form-entry-check"
            :class="{ 'is-error': !amountFCEqual }"
          >
            <div>
              <p>Dr</p>
              {{
                amountFCValue.Dr !== '-'
                  ? formatNumberToSignificantDigits(
                      Number(amountFCValue.Dr),
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  : '-'
              }}
            </div>
            <span>{{ amountFCEqual ? '=' : '≠' }}</span>
            <div>
              <p>Cr</p>
              {{
                amountFCValue.Cr !== '-'
                  ? formatNumberToSignificantDigits(
                      Number(amountFCValue.Cr),
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  : '-'
              }}
            </div>
          </div>
        </div>

        <div
          v-if="
            projectDetail?.plan !== ProjectPermissionLevel.OLD_BASIC &&
            journalForm.journalTypeId &&
            (isGeneralJournalType || (!isGeneralJournalType && journalForm.brokerInventoryType !== 'NONE'))
          "
          class="elv-transactions-add-journal-form-item__broker"
        >
          <div class="elv-transactions-add-journal-form-item-title">{{ t('title.brokerInventoryType') }}</div>
          <el-form-item label="" prop="brokerInventoryType">
            <el-select
              v-model="journalForm.brokerInventoryType"
              :disabled="!isEmpty(hasGstType) || (!isGeneralJournalType && journalForm.brokerInventoryType !== 'NONE')"
              @change="onChangeBrokerInventoryType"
            >
              <el-option
                v-for="item in brokerInventoryTypeOptions"
                :key="item.value"
                :label="transformI18n(item.label)"
                :value="item.value"
              />
            </el-select>
            <p v-if="brokerInventoryError" class="elv-transactions-add-journal-form-item__broker-error">
              {{ brokerInventoryError === 'SALE' ? t('message.brokerSaleInfo') : t('message.brokerPurchaseInfo') }}
            </p>
          </el-form-item>
        </div>

        <div class="elv-transactions-add-journal-form-item__memo">
          <div class="elv-transactions-add-journal-form-item-title">{{ t('title.memoAndAttachment') }}</div>
          <Memo ref="uploadMemoRef" :current-data="isEmpty(props.currentData) ? {} : props.currentData" />
        </div>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="saveLoading"
        :disabled="journalForm.brokerInventoryType !== 'SALE' && !amountFCEqual"
        @click="onSaveJournal"
        >{{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import {
  fieldValueFormat,
  formatInputAddress,
  formatNumberWithCommas,
  roundToTwoDecimalPlaces,
  formatNumberToSignificantDigits
} from '@/lib/utils'
import {
  find,
  isNumber,
  pick,
  map,
  sortBy,
  uniqBy,
  flatMap,
  isNaN,
  debounce,
  cloneDeep,
  findIndex,
  isEmpty
} from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import ConfigApi from '@/api/ConfigApi'
import PriceApi from '@/api/PriceApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import AccountsApi from '@/api/AccountsApi'
import timezoneList from '@/config/timezone'
// import { InputNumber } from 'ant-design-vue'
import { transformI18n } from '@/i18n/index'
import timezone from 'dayjs/plugin/timezone'
import DatetimeIcon from './DatetimeIcon.vue'
import { transactionType } from '@/config/index'
import { CurrencyItemType } from '#/ReportsTypes'
import TransactionsApi from '@/api/TransactionsApi'
import defaultImg from '@/assets/img/default-logo.png'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import type { FormInstance, FormRules } from 'element-plus'
import { useAccountStore } from '@/stores/modules/accounts'
import Memo from '../../Ledger/Journals/components/Memo.vue'
import { useGlobalStore } from '@/stores/modules/global/index'
import { useTransactionStore } from '@/stores/modules/transactions'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'
import ChartOfAccountOverlayDropdown from '../../components/ChartOfAccountOverlayDropdown.vue'
import JournalTypeOverlayDropdown from '@/pages/Financials/Project/components/JournalTypeOverlayDropdown.vue'
import { reportTransactionType, brokerInventoryTypeOptions, journalTypeGstOptions } from '@/config/reports/index'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  },
  tableType: {
    type: String,
    required: true
  },
  journalTypeList: {
    type: Array,
    default: () => {
      return []
    }
  },
  selectedJournalTypeId: {
    type: String,
    default: ''
  },
  auxiliaryAccountTypeId: {
    type: String,
    default: ''
  },
  model: {
    type: String,
    default: 'add' // add, edit
  },
  isRecategorize: {
    type: Boolean,
    default: false
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const route = useRoute()
const { t, locale } = useI18n()
const emit = defineEmits(['resetList', 'resetDetail'])
const { toClipboard } = useClipboard()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const { entityDetail, currencySign } = storeToRefs(entityStore)
const accountStore = useAccountStore()
const transactionStore = useTransactionStore()

const uploadMemoRef = useTemplateRef('uploadMemoRef')
const journalTypeSelectRef = useTemplateRef('journalTypeSelectRef')
const journalFormRef = useTemplateRef<FormInstance>('journalFormRef')
const journalTypeId = ref('')
const brokerInventoryValidate = ref(false)
const brokerInventoryError = ref('')
const currenciesData = ref<CurrencyItemType[]>([])
const currenciesRecommendData = ref<CurrencyItemType[]>([])
const currenciesCommonData: any = ref([
  {
    name: 'American Dollar',
    symbol: 'USD',
    logo: 'https://static.elven.com/financial/legal/usd.png',
    type: 'FIAT',
    detailPlatforms: null,
    uniqueId: 'legal-usd'
  },
  {
    name: 'Singapore Dollar',
    symbol: 'SGD',
    logo: 'https://static.elven.com/financial/legal/sgd.png',
    type: 'FIAT',
    detailPlatforms: null,
    uniqueId: 'legal-sgd'
  }
])
const currencyList = reactive<CurrencyItemType[][]>([])
const arrowRotate = ref('rotate(90deg)')
const currencyLoading = ref(false)
const showAddJournalDialog = ref(false)
const previewLoading = ref(false)
const searchLoading = ref(false)
const saveLoading = ref(false)
const amountFCLoading = ref([false, false, false])
const accountLoading = ref([false, false, false])
const originalGstTypeIndex = ref<null | number>(null)
const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const journalForm: any = ref({
  journalTypeId: '',
  transactionIdPairs: [],
  journalTypeGroup: 'OPERATING',
  timezone: entityStore.entityDetail?.timezone,
  datetimeStr: '',
  referenceNo: '',
  brokerInventoryType: 'NONE',
  entryLines: [
    {
      index: 0,
      balanceType: 'Dr',
      chartOfAccountId: '',
      currency: '',
      amount: '',
      amountFC: '',
      changeAmount: false,
      useManualPrice: false,
      gstRatio: 9,
      gstType: 'NONE',
      auxiliaryValueList: []
    },
    {
      index: 1,
      balanceType: 'Cr',
      chartOfAccountId: '',
      changeAmount: false,
      useManualPrice: false,
      currency: '',
      amount: '',
      amountFC: '',
      gstRatio: 9,
      gstType: 'NONE',
      auxiliaryValueList: []
    }
  ],
  memo: '',
  journalAttachmentIds: []
})

const projectDetail = computed(() => {
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const placeholderText = computed(() => {
  return t('placeholder.selectAccount')
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform.logo,
      isCheck: false
    }
  })
})

const accountDeletedOptions = computed(() => {
  const accountList = accountStore.accountList
    .filter((item: any) => {
      return item.status !== 'NORMAL'
    })
    .map((item: any) => {
      return {
        id: item.entityAccountId,
        value: item.entityAccountId,
        label: item.name,
        icon: item.platform.logo,
        isCheck: false
      }
    })
  if (accountList.length) {
    return [
      {
        title: t('title.deletedAccounts'),
        list: accountList
      }
    ]
  }
  return []
})

const selectedCurrency = useComputedHook((symbol: string) => {
  return find(currenciesCommonData.value, { symbol })
})

const transactionTypeIcon = computed(() => {
  let icon = 'outflow-arrow'
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === props.currentData?.type) {
      icon = item.icon
    }
  })
  return icon
})

const generalJournalTypeId = computed(() => {
  const generalJournal: any = find(transactionStore.journalTypeList, { slug: 'general_journal' })
  return generalJournal?.journalTypeId ?? ''
})

const enableGst = computed(() => {
  return entityStore.entityDetail?.enableGst
})

const currentJournalTypeData = computed(() => {
  let data: any = {}
  data = find(transactionStore.journalTypeList, { journalTypeId: journalForm.value.journalTypeId })
  return data
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const hasGstType = computed(() => {
  return journalForm.value.entryLines.find((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA' && i.gstType !== '')
})

const isGeneralJournalType = computed(() => {
  if (props.selectedJournalTypeId) {
    return props.selectedJournalTypeId === generalJournalTypeId.value
  }
  return journalForm.value.journalTypeId === generalJournalTypeId.value
})

// 是否为多借多贷
const isCompoundEntry = computed(() => {
  return currentJournalTypeData.value?.entryType === 'COMPOUND'
})

const showGSTSelect: any = useComputedHook((chartOfAccountId: string) => {
  const slugs = ['gst_output', 'gst_input', 'tax_payable']
  const types = ['ASSET', 'LIABILITY', 'EQUITY']
  const chartOfAccount = currentChartOfAccount(chartOfAccountId).value
  return !slugs?.includes(chartOfAccount?.slug) && types?.includes(chartOfAccount?.type)
})

const disabledAmount = computed(() => {
  return (item: any) => {
    const isEvenIndex = (item.index + 1) % 2 === 0
    const isDr = item.balanceType === 'Dr'
    const isCr = item.balanceType === 'Cr'
    const { entryLines } = journalForm.value
    const gstBalanceType = entryLines.find((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA')?.balanceType ?? ''
    const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value

    const hasMoreThanTwoLines = entryLines?.length > 2
    const hasTwoLines = entryLines?.length === 2
    const firstLineHasGst =
      entryLines[0]?.gstType !== 'NONE' && entryLines[0]?.gstType !== 'NA' && entryLines[0]?.gstType !== ''
    const thirdLineHasGst =
      entryLines[2]?.gstType !== 'NONE' && entryLines[2]?.gstType !== 'NA' && entryLines[2]?.gstType !== ''
    const secondLineHasGst =
      entryLines[1]?.gstType !== 'NONE' && entryLines[1]?.gstType !== 'NA' && entryLines[1]?.gstType !== ''

    if (
      journalForm.value.brokerInventoryType === 'SALE' &&
      isDr &&
      item?.chartOfAccountId &&
      chartOfAccount?.type === 'EXPENSE'
    )
      return true
    if (enableGst.value && !isCompoundEntry.value && hasGstType.value) {
      if (isDr && isEvenIndex) return true
      if (isCr && !isEvenIndex && currentChartOfAccount(entryLines[1]?.chartOfAccountId).value?.slug !== 'gst_input')
        return true
      if (
        isCr &&
        gstBalanceType === 'Dr' &&
        ((isEvenIndex &&
          hasMoreThanTwoLines &&
          (!entryLines[1]?.currency ||
            currentChartOfAccount(entryLines[1]?.chartOfAccountId).value?.[0] === 'REPORTING')) ||
          (hasTwoLines &&
            item.index === 1 &&
            (!entryLines[1]?.currency ||
              currentChartOfAccount(entryLines[1]?.chartOfAccountId).value?.[0] === 'REPORTING'))) &&
        firstLineHasGst
      )
        return true
      if (
        isDr &&
        gstBalanceType === 'Cr' &&
        item.index === 0 &&
        ((hasMoreThanTwoLines && secondLineHasGst) ||
          (hasMoreThanTwoLines &&
            thirdLineHasGst &&
            (!entryLines[0]?.currency ||
              currentChartOfAccount(entryLines[0]?.chartOfAccountId).value?.[0] === 'REPORTING')) ||
          (hasTwoLines &&
            secondLineHasGst &&
            (!entryLines[0]?.currency ||
              currentChartOfAccount(entryLines[0]?.chartOfAccountId).value?.[0] === 'REPORTING')))
      )
        return true
    }

    return false
  }
})

const disabledChartOrAccount = computed(() => {
  return (item: any) => {
    const isEvenIndex = (item.index + 1) % 2 === 0
    const isDr = item.balanceType === 'Dr'
    const isCr = item.balanceType === 'Cr'
    const { entryLines } = journalForm.value
    const gstBalanceType = entryLines.find((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA')?.balanceType
    const hasMoreThanTwoLines = entryLines?.length > 2
    const firstLineHasGst = entryLines[0]?.gstType !== 'NONE' && entryLines[0]?.gstType !== 'NA'
    const secondLineHasGst = entryLines[1]?.gstType !== 'NONE' && entryLines[1]?.gstType !== 'NA'
    const thirdLineHasGst = entryLines[2]?.gstType !== 'NONE' && entryLines[2]?.gstType !== 'NA'

    if (enableGst.value) {
      if (isCr && gstBalanceType === 'Dr' && hasMoreThanTwoLines && !isEvenIndex && firstLineHasGst) return true
      if (isCr && gstBalanceType === 'Cr' && hasMoreThanTwoLines && !isEvenIndex && secondLineHasGst) return true
      if (isDr && gstBalanceType === 'Cr' && hasMoreThanTwoLines && isEvenIndex && thirdLineHasGst) return true
    }

    return false
  }
})

const disabledGstType: any = useComputedHook((item: any) => {
  if (journalForm.value.brokerInventoryType === 'SALE') return true
  const hasDrAndCr =
    journalForm.value.entryLines.some((entry: any) => entry.balanceType === 'Dr') &&
    journalForm.value.entryLines.some((entry: any) => entry.balanceType === 'Cr')

  if (
    journalForm.value.entryLines.length < 2 ||
    (journalForm.value.entryLines.length > 2 && !hasGstType.value) ||
    !hasDrAndCr
  )
    return true
  const gstBalanceType = journalForm.value.entryLines.find(
    (i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA'
  )?.balanceType
  if (gstBalanceType) {
    return item.balanceType !== gstBalanceType
  }
  return false
})

const rules = reactive<FormRules>({
  journalTypeId: {
    required: true,
    trigger: 'blur',
    message: 'Journal Type is required'
  },
  journalTypeGroup: {
    required: true,
    trigger: 'blur',
    message: 'Activity is required'
  },
  datetimeStr: {
    required: true,
    trigger: 'blur',
    message: 'Datetime is required'
  },
  timezone: {
    required: true,
    trigger: 'blur',
    message: 'Timezone is required'
  },
  brokerInventoryType: {
    required: true,
    trigger: 'blur',
    validator() {
      if (brokerInventoryValidate.value) {
        const DrEntryList = journalForm.value.entryLines.filter((i: any) => i.balanceType === 'Dr')
        const CrEntryList = journalForm.value.entryLines.filter((i: any) => i.balanceType === 'Cr')
        switch (journalForm.value.brokerInventoryType) {
          case 'PURCHASE':
            const meetTheCriteria = DrEntryList.some((i: any) => {
              const chartOfAccount = currentChartOfAccount(i?.chartOfAccountId).value
              if (
                ['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) &&
                chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'
              ) {
                return true
              }
              return false
            })
            brokerInventoryError.value = meetTheCriteria ? '' : 'PURCHASE'
            return !meetTheCriteria ? new Error('') : true
          case 'SALE':
            const CrMeetTheCriteria = CrEntryList.every((i: any) => {
              const chartOfAccount = currentChartOfAccount(i?.chartOfAccountId).value
              if (
                ['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) &&
                chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'
              ) {
                return true
              }
              return false
            })
            let DrMeetTheCriteria = false
            if (DrEntryList.length === 1) {
              const DrChartOfAccount = currentChartOfAccount(DrEntryList[0]?.chartOfAccountId).value
              const CrNotIncludeGst = CrEntryList.every(
                (i: any) => i.gstType === 'NONE' || i.gstType === 'NA' || i.gstType === ''
              )
              DrMeetTheCriteria = CrNotIncludeGst && DrChartOfAccount?.type === 'EXPENSE'
            }
            brokerInventoryError.value = CrMeetTheCriteria && DrMeetTheCriteria ? '' : 'SALE'
            return !(CrMeetTheCriteria && DrMeetTheCriteria) ? new Error('') : true
          default:
            return true
        }
      }
      return true
    }
  }
})

const showEntryAccount: any = computed(() => (item: any, index: number) => {
  const isTreasuryAccount =
    currentJournalTypeData.value?.entryLines?.[index]?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
  if (item.chartOfAccountId && !isTreasuryAccount) {
    const chartOfAccount = currentChartOfAccount(item.chartOfAccountId).value
    if (chartOfAccount?.auxiliaryTypes?.length) {
      return {
        type: 'AUXILIARY_CODE',
        show: true
      }
    }
  }
  if (isEmpty(props.currentData) || (!isGeneralJournalType.value && !isEmpty(props.currentData))) {
    return {
      type: 'TREASURY_ACCOUNT',
      show: isTreasuryAccount
    }
  }
  return { type: '', show: false }
})

const amountFCValue = computed(() => {
  let amountFCDr: any = '-'
  let amountFCCr: any = '-'
  journalForm.value.entryLines.forEach((item: any) => {
    if (item.balanceType === 'Dr') {
      if (item.amountFC !== '') {
        amountFCDr = Number(
          new Big(amountFCDr !== '-' ? amountFCDr : '0').plus(
            new Big(item.amountFC !== '' && item.amountFC !== null ? item.amountFC : '0')
          )
        )
      } else if (amountFCDr === '-' || amountFCDr === '') {
        amountFCDr = '-'
      }
    } else if (item.balanceType === 'Cr') {
      if (item.amountFC !== '') {
        amountFCCr = Number(
          new Big(amountFCCr !== '-' ? amountFCCr : '0').plus(
            new Big(item.amountFC !== '' && item.amountFC !== null ? item.amountFC : '0')
          )
        )
      } else if (amountFCCr === '-' || amountFCCr === '') {
        amountFCCr = '-'
      }
    }
  })
  return {
    Dr: amountFCDr !== '-' ? roundToTwoDecimalPlaces(amountFCDr) : '-',
    Cr: amountFCCr !== '-' ? roundToTwoDecimalPlaces(amountFCCr) : '-'
  }
})

// Dr于Cr是否相等
const amountFCEqual = computed(() => {
  if (amountFCValue.value.Dr === '-' && amountFCValue.value.Cr === '-') return false
  return amountFCValue.value.Dr === amountFCValue.value.Cr
})

/**
 * @description: 表单校验Currency
 * @param {any} rule
 * @param {any} value
 * @param {any} callback
 * @return {*}
 */
const validatorCurrency = (rule: any, value: any, callback: any): any => {
  if (!value) {
    callback(new Error('Currency is required'))
  } else {
    callback()
  }
}

/**
 * @description: 表单校验Amount
 * @param {any} item
 * @return {any}
 */
const validatorAmount = (item: any): any => {
  return (rule: any, value: any, callback: any, source: any, options: any, entry = item) => {
    const chartOfAccount = currentChartOfAccount(entry?.chartOfAccountId).value
    if (
      journalForm.value.brokerInventoryType === 'SALE' &&
      entry.balanceType === 'Dr' &&
      chartOfAccount?.type === 'EXPENSE'
    ) {
      callback()
    } else if (
      entry?.amount === '' ||
      (entry?.amountOrigin && !isNumber(Number(entry.amountOrigin))) ||
      (isNaN(Number(entry?.amount)) && !entry?.amountOrigin)
    ) {
      callback(new Error('Amount is required'))
    } else {
      callback()
    }
  }
}

/**
 * @description: 表单校验Account
 * @param {any} item
 * @return {any}
 */
const validatorAccount = (item: any): any => {
  return (rule: any, value: any, callback: any, source: any, options: any, entry = item) => {
    if (!entry?.auxiliaryValueList[0]?.entityAccountId) {
      callback(new Error('Auxiliary Code / Account is required'))
    } else {
      callback()
    }
  }
}

/**
 * @description: 是否禁用dateTime
 * @param {Date} time
 * @return {boolean}
 */
const disabledDate = (time: Date): boolean => {
  if (entityStore.entityDetail?.startingDate) {
    return time.getTime() < dayjs(entityStore.entityDetail?.startingDate).valueOf()
  }
  return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
}

/**
 * @description: 过滤辅助值列表
 * @param {any[]} auxiliaryValueList
 * @param {string} chartOfAccountId
 * @return {any}
 */
const filterAuxiliaryValueList = (auxiliaryValueList: any, chartOfAccountId: string) => {
  const chartOfAccount = currentChartOfAccount(chartOfAccountId).value
  if (!auxiliaryValueList.length && chartOfAccountId && chartOfAccount?.auxiliaryTypes?.length) {
    const auxiliaryList = map(chartOfAccount.auxiliaryTypes, (item: any) => {
      const auxiliary: any = {
        auxiliaryTypeId: item.auxiliaryTypeId
      }
      if (item.type === 'ENTITY_ACCOUNT') {
        auxiliary.entityAccountId = ''
      } else if (item.type === 'COUNTERPARTY') {
        auxiliary.counterpartyId = ''
      } else if (item.type === 'ITEM') {
        auxiliary.auxiliaryItemId = ''
      }
      return auxiliary
    })
    return auxiliaryList
  }
  let auxiliaryValues = auxiliaryValueList
  if (chartOfAccount?.auxiliaryTypes?.length) {
    auxiliaryValues = map(chartOfAccount.auxiliaryTypes, (item: any) => {
      const auxiliary = find(auxiliaryValueList, { auxiliaryTypeId: item.auxiliaryTypeId })
      return auxiliary ? { ...item, ...auxiliary } : item
    })
  }
  return auxiliaryValues.map((item: any) => {
    if (item.auxiliaryType?.type === 'ENTITY_ACCOUNT') {
      return {
        ...item,
        entityAccountId: item?.entityAccount?.entityAccountId ?? ''
      }
    }
    if (item.auxiliaryType?.type === 'COUNTERPARTY') {
      return {
        ...item,
        counterpartyId: item?.counterparty?.counterpartyId ?? ''
      }
    }
    let auxiliaryItemId = ''
    if (
      find(item.auxiliaryType?.auxiliaryItems, { auxiliaryItemId: item?.auxiliaryItemId }) ||
      item?.auxiliaryItem?.auxiliaryItemId
    ) {
      auxiliaryItemId = item.auxiliaryItemId ?? item?.auxiliaryItem?.auxiliaryItemId ?? ''
    }
    return {
      ...item,
      auxiliaryItemId
    }
  })
}

/**
 * @description: 推导treasury account
 * @param {number} index
 */
const previewJournalTreasuryAccount = async (index: number) => {
  try {
    accountLoading.value[index] = true
    previewLoading.value = true
    const params = {
      currency: journalForm.value.entryLines[index].currency,
      auxiliaryValueList: journalForm.value.entryLines[index].auxiliaryValueList
    }
    const { data } = await TransactionsApi.previewJournalTreasuryAccount(entityId.value, params)
    journalForm.value.entryLines[index].chartOfAccountId = data.chartOfAccountId
    const chartOfAccount = currentChartOfAccount(data.chartOfAccountId).value
    if (chartOfAccount?.auxiliaryTypes?.length) {
      const auxiliaryList = map(chartOfAccount.auxiliaryTypes, (item: any) => {
        const auxiliary: any = {
          auxiliaryTypeId: item.auxiliaryTypeId
        }
        if (item.type === 'ENTITY_ACCOUNT') {
          auxiliary.entityAccountId = ''
        } else if (item.type === 'COUNTERPARTY') {
          auxiliary.counterpartyId = ''
        } else if (item.type === 'ITEM') {
          auxiliary.auxiliaryItemId = ''
        }
        return auxiliary
      })
      journalForm.value.entryLines[index].auxiliaryValueList = auxiliaryList.map((item: any) => {
        const auxiliary = find(journalForm.value.entryLines[index]?.auxiliaryValueList, {
          auxiliaryTypeId: item?.auxiliaryTypeId
        })
        return auxiliary ? { ...item, ...auxiliary } : item
      })
    }
  } catch (error: any) {
    ElMessage.error(error.message)
    journalForm.value.entryLines[index].chartOfAccountId = ''
  } finally {
    accountLoading.value[index] = false
    previewLoading.value = false
  }
}

/**
 * @description: 远程搜索Currency
 * @param {number} index
 */
const remoteCurrencyMethod = (index: number) => {
  const dataIndex = index < 0 ? 0 : index
  const isTreasuryAccount =
    showEntryAccount.value(journalForm.value.entryLines[dataIndex], dataIndex)?.type === 'TREASURY_ACCOUNT'
  if (journalForm.value.entryLines[dataIndex].chartOfAccountId === '' && !isTreasuryAccount) return
  // eslint-disable-next-line consistent-return
  return async (query: string) => {
    if (query) {
      try {
        currencyLoading.value = true
        const currencyTypes =
          currentChartOfAccount(journalForm.value.entryLines?.[dataIndex]?.chartOfAccountId).value?.currencyTypes ?? []
        const params = {
          recommend: false,
          keywords: [query],
          entityId: entityId.value,
          type: isTreasuryAccount ? [] : currencyTypes
        }
        const { data } = await ConfigApi.searchCurrencyList(params)
        currenciesData.value = data
        const list = [...cloneDeep(currenciesCommonData.value), ...data]
        currenciesCommonData.value = uniqBy(list, 'symbol')
      } catch (error) {
        console.log(error)
        currenciesData.value = []
      } finally {
        currencyLoading.value = false
      }
    } else {
      currenciesData.value = currencyList[dataIndex]
    }
  }
}

/**
 * @description: 远程搜索Counterparty
 * @param {string} query
 */
const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

/**
 * @description:  重置Entry恢复初始状态
 * @param {number|null} index
 */
const onResetEntry = (index: number | null = null) => {
  // 重置单个
  if (index !== null) {
    amountFCLoading.value[index] = false
    accountLoading.value[index] = false
    journalForm.value.entryLines[index] = {
      index,
      balanceType: journalForm.value.entryLines[index].balanceType,
      chartOfAccountId: '',
      currency: '',
      amount: '',
      amountFC: '',
      changeAmount: false,
      useManualPrice: false,
      gstRatio: journalForm.value.entryLines[index].gstRatio ?? 9,
      gstType: journalForm.value.entryLines[index].gstType ?? 'NONE',
      auxiliaryValueList: []
    }
    if (
      currentJournalTypeData.value?.entryLines?.[index]?.chartOfAccountCalculator === 'TREASURY_ACCOUNT' &&
      !isGeneralJournalType.value
    ) {
      journalForm.value.entryLines[index].auxiliaryValueList = [
        {
          auxiliaryTypeId: props.auxiliaryAccountTypeId,
          entityAccountId: ''
        }
      ]
    }
  } else {
    // 重置全部
    amountFCLoading.value = [false, false, false]
    accountLoading.value = [false, false, false]
    journalForm.value.entryLines = [
      {
        index: 0,
        balanceType: 'Dr',
        chartOfAccountId: '',
        currency: '',
        amount: '',
        amountFC: '',
        changeAmount: false,
        useManualPrice: false,
        gstRatio: 9,
        gstType: 'NONE',
        auxiliaryValueList: []
      },
      {
        index: 1,
        balanceType: 'Cr',
        chartOfAccountId: '',
        currency: '',
        amount: '',
        amountFC: '',
        changeAmount: false,
        useManualPrice: false,
        gstRatio: 9,
        gstType: 'NONE',
        auxiliaryValueList: []
      }
    ]
  }
}

/**
 * @description: 新增Entry
 * @param {string} balanceType Dr/Cr
 */
const onAddEntry = (balanceType: string) => {
  journalForm.value.entryLines.push({
    index: journalForm.value.entryLines.length,
    balanceType,
    chartOfAccountId: '',
    currency: '',
    amount: '',
    amountFC: '',
    changeAmount: false,
    useManualPrice: false,
    gstRatio: 9,
    gstType: 'NONE',
    auxiliaryValueList: []
  })
}

/**
 * @description: 删除Entry
 * @param {number} index
 */
const onDeleteEntry = (index: number) => {
  if (hasGstType.value) return
  journalForm.value.entryLines.splice(index, 1)
  journalForm.value.entryLines.forEach((item: any, i: number) => {
    item.index = i
  })
}

/**
 * @description: 变更Broker Inventory Type
 * @param {string} type
 */
const onChangeBrokerInventoryType = (type: string) => {
  brokerInventoryValidate.value = false
  brokerInventoryError.value = ''
  switch (type) {
    case 'SALE':
      journalForm.value.entryLines.forEach((item: any) => {
        const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
        if (item.balanceType === 'Dr' && item.chartOfAccountId !== '' && chartOfAccount?.type === 'EXPENSE') {
          item.amount = 'Based on cost basis'
          item.amountFC = ''
        }
      })
      break
    case 'NONE':
      journalForm.value.entryLines.forEach((item: any) => {
        const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
        if (
          item.balanceType === 'Dr' &&
          item.amount === 'Based on cost basis' &&
          item.chartOfAccountId !== '' &&
          chartOfAccount?.type === 'EXPENSE'
        ) {
          item.amount = ''
          item.amountFC = ''
        }
      })
      break
    default:
      break
  }
}

/**
 * @description: 变更GST Rate Type
 * @param {number|null} index
 */
const onChangeGSTRateType = async (index: number | null = null) => {
  if (index !== null) {
    if (
      journalForm.value.entryLines[index].gstType === 'NONE' ||
      journalForm.value.entryLines[index].gstType === 'ZERO' ||
      journalForm.value.entryLines[index].gstType === 'EXEMPT'
    ) {
      journalForm.value.entryLines[index].gstRatio = 0
    } else {
      journalForm.value.entryLines[index].gstRatio = 9
    }
    if (journalForm.value.entryLines[index].gstType === 'NONE') {
      if (journalForm.value.entryLines?.length > 2) {
        const gstIndex = findIndex(journalForm.value.entryLines, (i: any) => {
          const chartOfAccount = currentChartOfAccount(i.chartOfAccountId).value
          if (chartOfAccount?.slug === 'gst_input' || chartOfAccount?.slug === 'gst_output') return true
          return false
        })
        journalForm.value.entryLines.splice(gstIndex, 1)
        journalForm.value.entryLines.forEach((item: any, i: number) => {
          item.index = i
        })
      } else {
        onResetEntry(index === 0 ? 1 : 0)
        journalForm.value.entryLines[index === 0 ? 1 : 0] = {
          index,
          balanceType: journalForm.value.entryLines[index === 0 ? 1 : 0].balanceType,
          chartOfAccountId: '',
          currency: '',
          amount: '',
          amountFC: '',
          changeAmount: false,
          useManualPrice: journalForm.value.entryLines[index === 0 ? 1 : 0].useManualPrice,
          gstRatio: 9,
          gstType: 'NONE',
          auxiliaryValueList: []
        }
      }
    }
  }
  if (index !== null && journalForm.value.entryLines[index]?.gstType === 'NONE') return
  if (
    index === null &&
    journalForm.value.entryLines.every(
      (item: any) => item.gstType === 'NONE' || item.gstType === '' || item.gstType === 'NA'
    )
  ) {
    journalForm.value.entryLines.forEach((entry: any, i: number) => {
      if (entry.amount && !entry?.useManualPrice) {
        // eslint-disable-next-line no-use-before-define
        onChangeAmountFun(i)
      }
    })
    return
  }
  try {
    const params = {
      timezone: journalForm.value.timezone,
      journalTypeId: journalForm.value.journalTypeId,
      datetimeStr: dayjs(journalForm.value.datetimeStr).format('YYYY-MM-DD HH:mm:ss'),
      entryLines: cloneDeep(journalForm.value.entryLines)
    }
    // 删除entryLines数组中的index与其他key值为空的字段
    params.entryLines.forEach((item: any) => {
      delete item.index
      item.auxiliaryValueList = item.auxiliaryValueList.filter(
        (i: any) =>
          (i?.entityAccountId !== '' && i?.entityAccountId !== undefined) ||
          (i?.counterpartyId !== '' && i?.counterpartyId !== undefined) ||
          (i?.auxiliaryItemId !== '' && i?.auxiliaryItemId !== undefined)
      )
      // eslint-disable-next-line no-nested-ternary
      item.amount = item?.amountOrigin ? Number(item?.amountOrigin) : item.amount !== '' ? Number(item.amount) : ''
      item.gstRatio = item.gstRatio === '' ? '' : item.gstRatio / 100
      delete item.amountOrigin
    })
    previewLoading.value = true
    const { data } = await TransactionsApi.journalGstPreview(entityId.value, params)
    const sortEntryLines = sortBy(data.entryLines, (item: any) => {
      // Dr在前Cr在后
      return item.balanceType === 'Dr' ? 0 : 1
    })
    const entryLines = sortEntryLines.map((item: any, i: number) => {
      const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
      const entityAccount = find(accountStore.accountList, { entityAccountId: item?.entityAccountId })
      const entryData: any = {
        index: i,
        balanceType: item.balanceType,
        chartOfAccountId:
          item.chartOfAccountId !== '0' && chartOfAccount !== undefined ? (item.chartOfAccountId ?? '') : '',
        // eslint-disable-next-line no-nested-ternary
        currency: item?.currency
          ? item?.currency
          : chartOfAccount?.currencyTypes?.[0] === 'REPORTING'
            ? entityDetail.value?.defaultCurrency
            : '',
        amount: item?.amount ?? '',
        amountFC: item?.amountFC ?? '',
        changeAmount: true,
        useManualPrice: item?.useManualPrice ?? false,
        auxiliaryValueList: journalForm.value.entryLines[i]?.auxiliaryValueList ?? [],
        entityAccountId:
          item?.entityAccountId !== '0' && entityAccount !== undefined ? (item?.entityAccountId ?? '') : '',
        gstType:
          // eslint-disable-next-line no-nested-ternary
          item?.gstType !== null
            ? item?.gstType
            : chartOfAccount?.slug === 'gst_input' || chartOfAccount?.slug === 'gst_output'
              ? 'NA'
              : 'NONE',
        gstRatio: item?.gstRatio ? Number(item.gstRatio * 100) : 0
      }
      if (
        (entryData.gstType === 'NA' || entryData.gstType === 'NONE') &&
        item?.amount !== null &&
        item?.amount !== '' &&
        item?.amount !== undefined
      ) {
        entryData.amountOrigin = item.amount
        entryData.amount = formatNumberToSignificantDigits(Number(item.amount), 2, '', false)
      }
      if (chartOfAccount?.auxiliaryTypes?.length) {
        const auxiliaryList = map(chartOfAccount.auxiliaryTypes, (auxiliaryItem: any) => {
          const auxiliary: any = {
            auxiliaryTypeId: auxiliaryItem.auxiliaryTypeId
          }
          if (auxiliaryItem.type === 'ENTITY_ACCOUNT') {
            auxiliary.entityAccountId = ''
          } else if (auxiliaryItem.type === 'COUNTERPARTY') {
            auxiliary.counterpartyId = ''
          } else if (auxiliaryItem.type === 'ITEM') {
            auxiliary.auxiliaryItemId = ''
          }
          return auxiliary
        })
        entryData.auxiliaryValueList = auxiliaryList.map((auxiliaryItem: any) => {
          const auxiliary = find(journalForm.value.entryLines[i]?.auxiliaryValueList, {
            auxiliaryTypeId: auxiliaryItem?.auxiliaryTypeId
          })
          return auxiliary ? { ...auxiliaryItem, ...auxiliary } : auxiliaryItem
        })
      }
      if (entryData.gstType !== 'NONE' && entryData.gstType !== '' && entryData.gstType !== 'NA') {
        originalGstTypeIndex.value = i
      }
      return entryData
    })
    journalForm.value.entryLines = entryLines
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    previewLoading.value = false
  }
}

/**
 * @description: 输入gst Rate
 * @param {number} index
 */
const onChangeGSTRate = debounce((index: number) => {
  onChangeGSTRateType(index)
}, 500)

/**
 * @description: 是否显示GST
 * @param {number} index
 * @param {string} type
 * @param {boolean} checkAmount
 * @param {boolean} checkCurrency
 * @return {boolean}
 */
const checkEntryLine = (
  index: number,
  type: string,
  checkAmount: boolean = true,
  checkCurrency: boolean = false
): boolean => {
  const entry = journalForm.value.entryLines?.[index]
  return (
    entry &&
    entry?.balanceType === type &&
    (!checkAmount || entry?.amountFC !== '') &&
    (!checkCurrency || entry?.currency !== '')
  )
}

/**
 * @description: 金额改变后进行GST Preview
 */
const afterChangingAmountGSTPreview = () => {
  if (entityStore.entityDetail?.enableGst) {
    // Dr与Cr amountFC都有值时,Preview
    const entryLength = journalForm.value.entryLines?.length
    if (!isCompoundEntry.value && !isEmpty(hasGstType.value)) {
      if (
        (checkEntryLine(0, 'Dr', false) &&
          checkEntryLine(1, 'Dr', false) &&
          checkEntryLine(2, 'Cr', false, true) &&
          !isGeneralJournalType.value) ||
        (checkEntryLine(0, 'Dr') && checkEntryLine(2, 'Cr', false) && isGeneralJournalType.value) ||
        (checkEntryLine(0, 'Dr', false, true) &&
          checkEntryLine(1, 'Cr', false, true) &&
          isGeneralJournalType.value &&
          entryLength === 2) ||
        (checkEntryLine(0, 'Dr', false, true) &&
          checkEntryLine(1, 'Cr', false) &&
          checkEntryLine(2, 'Cr', false) &&
          !isGeneralJournalType.value) ||
        (checkEntryLine(1, 'Dr', false) && checkEntryLine(2, 'Cr') && isGeneralJournalType.value)
      ) {
        onChangeGSTRateType()
      }
    }
  }
}

/**
 * @description: 输入金额
 * @param {number} index
 */
const onChangeAmountFun = async (index: number) => {
  try {
    if (
      isNaN(Number(journalForm.value.entryLines[index].amount)) ||
      journalForm.value.entryLines[index].amount === ''
    ) {
      journalForm.value.entryLines[index].changeAmount = false
      journalForm.value.entryLines[index].amountFC = ''
      return
    }
    // 计算价格汇率
    if (
      journalForm.value.entryLines[index].currency !== entityDetail.value?.defaultCurrency &&
      journalForm.value.entryLines[index].currency !== '' &&
      !journalForm.value.entryLines[index]?.useManualPrice &&
      (hasGstType.value?.index === index || isEmpty(hasGstType.value))
    ) {
      amountFCLoading.value[index] = true
      const params = {
        currency: journalForm.value.entryLines[index].currency,
        datetimeStr: dayjs(journalForm.value.datetimeStr).format('YYYY-MM-DD HH:mm:ss'),
        timezone: journalForm.value.timezone,
        amount: journalForm.value.entryLines[index].amount,
        entityId: entityId.value,
        quoteCurrency: entityStore.entityDetail?.defaultCurrency ?? 'USD'
      }
      const { data } = await PriceApi.getCurrencyPriceCalculate(params)
      journalForm.value.entryLines[index].amountFC = data.amountFC
      if (journalForm.value.entryLines.length === 2) {
        const otherEntry = journalForm.value.entryLines[index === 0 ? 1 : 0]
        if (
          otherEntry?.chartOfAccountId &&
          otherEntry?.currency === entityDetail.value?.defaultCurrency &&
          !otherEntry?.changeAmount &&
          journalForm.value.brokerInventoryType !== 'SALE'
        ) {
          otherEntry.amount = journalForm.value.entryLines[index].amountFC
          otherEntry.amountFC = journalForm.value.entryLines[index].amountFC
        }
      }

      amountFCLoading.value[index] = false
    } else if (journalForm.value.entryLines[index].currency === entityDetail.value?.defaultCurrency) {
      journalForm.value.entryLines[index].amountFC = Number(journalForm.value.entryLines[index].amount)
      journalForm.value.entryLines[index].changeAmount = true
    } else if (
      !journalForm.value.entryLines[index]?.useManualPrice &&
      (hasGstType.value?.index === index || isEmpty(hasGstType.value))
    ) {
      journalForm.value.entryLines[index].amountFC = ''
    }
    afterChangingAmountGSTPreview()
  } catch (error) {
    console.log(error)
  }
}

/**
 * @description: 输入金额-防抖
 */
const onChangeAmount = debounce(onChangeAmountFun, 500)

/**
 * @description: 手动输入功能币种金额-防抖
 */
const onChangeManuallyAmountFC = debounce(afterChangingAmountGSTPreview, 500)

/**
 * @description: 功能币种计算切换为手动计算
 * @param {any} item
 */
const onSwitchAmountFCManuallyCalculate = (item: any) => {
  if (!amountFCLoading.value[item?.index] && !disabledAmount.value(item) && !item.useManualPrice) {
    if (!isEmpty(hasGstType.value) && hasGstType.value?.index !== item.index) return
    journalForm.value.entryLines[item?.index].useManualPrice = true
  }
}

/**
 * @description: 功能币种计算切换为手动计算
 * @param {any} item
 */
const onSwitchAmountFCAutoCalculate = (item: any) => {
  if (!amountFCLoading.value[item?.index] && !disabledAmount.value(item) && item.useManualPrice) {
    journalForm.value.entryLines[item?.index].useManualPrice = false
    onChangeAmountFun(item.index)
  }
}

/**
 * @description: 更改币种
 * @param {number} index
 */
const onChangeCurrency = (index: number) => {
  // 与Amount联动
  if (journalForm.value.entryLines[index].amount !== '') {
    onChangeAmountFun(index)
  }
  if (
    showEntryAccount.value(journalForm.value.entryLines[index], index)?.type === 'TREASURY_ACCOUNT' &&
    journalForm.value.entryLines[index].auxiliaryValueList.some(
      (item: any) =>
        (item?.entityAccountId !== '' && item?.entityAccountId !== undefined) ||
        (item?.counterpartyId !== '' && item?.counterpartyId !== undefined) ||
        (item?.auxiliaryItemId !== '' && item?.auxiliaryItemId !== undefined)
    )
  ) {
    // 推导treasury account
    previewJournalTreasuryAccount(index)
  }

  if (entityStore.entityDetail?.enableGst && isGeneralJournalType.value) {
    if (!isEmpty(hasGstType.value) && hasGstType.value?.amountFC !== '') {
      onChangeGSTRateType()
    }
  }
}

/**
 * @description: 更改账户
 * @param {number} index
 */
const onChangeAccount = (index: number) => {
  if (
    showEntryAccount.value(journalForm.value.entryLines[index], index)?.type === 'TREASURY_ACCOUNT' &&
    journalForm.value.entryLines[index].currency !== ''
  ) {
    // 推导treasury account
    previewJournalTreasuryAccount(index)
  }
}

/**
 * @description: 图片加载错误默认图片
 * @param {Event} event
 */
const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

/**
 * @description: 点击复制
 * @param {string} msg
 */
const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

/**
 * @description: 点击选择箭头更改方向
 * @param {boolean} status
 */
const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

/**
 * @description: 选择ChartOfAccount
 * @param {number} index
 */
const onChangeChartOfAccount = async (index: number) => {
  if (journalForm.value.entryLines[index].chartOfAccountId) {
    const chartOfAccount = currentChartOfAccount(journalForm.value.entryLines[index].chartOfAccountId).value
    journalForm.value.entryLines[index].auxiliaryValueList = chartOfAccount.auxiliaryTypes?.length
      ? chartOfAccount.auxiliaryTypes.map((item: any) => {
          const auxiliaryType: any = {
            auxiliaryTypeId: item.auxiliaryTypeId
          }
          if (item.type === 'ENTITY_ACCOUNT') {
            auxiliaryType.entityAccountId = ''
          } else if (item.type === 'COUNTERPARTY') {
            auxiliaryType.counterpartyId = ''
          } else if (item.type === 'ITEM') {
            auxiliaryType.auxiliaryItemId = ''
          }
          return auxiliaryType
        })
      : []
    if (isEmpty(chartOfAccount)) return
    if (isGeneralJournalType.value) {
      onResetEntry(index)
      journalForm.value.entryLines[index].chartOfAccountId = chartOfAccount?.chartOfAccountId ?? ''
      if (!showGSTSelect(chartOfAccount.chartOfAccountId).value) {
        journalForm.value.entryLines[index].gstType = 'NONE'
        journalForm.value.entryLines[index].gstRatio = 0
      }
      if (journalForm.value.entryLines?.length > 2 && !isEmpty(hasGstType.value)) {
        const removeEntryLine = (i: number, balanceType: string) => {
          if (journalForm.value.entryLines[i].balanceType === balanceType) {
            journalForm.value.entryLines.splice(i, 1)
          }
        }
        journalForm.value.entryLines.forEach((item: any) => {
          const chartOfAccountVal = currentChartOfAccount(item.chartOfAccountId).value
          if (chartOfAccountVal?.slug === 'gst_output' || chartOfAccountVal?.slug === 'gst_input') {
            removeEntryLine(item.index, item.balanceType)
          }
        })
        journalForm.value.entryLines.forEach((item: any, i: number) => {
          item.index = i
        })
      }
      const isGstEntry = chartOfAccount?.slug === 'gst_output' || chartOfAccount?.slug === 'gst_input'
      journalForm.value.entryLines[index].currency =
        // eslint-disable-next-line no-nested-ternary
        chartOfAccount?.currencyTypes?.[0] === 'REPORTING'
          ? entityDetail.value?.defaultCurrency
          : isGstEntry
            ? 'SGD'
            : ''
      journalForm.value.entryLines[index].auxiliaryValueList = chartOfAccount.auxiliaryTypes?.length
        ? chartOfAccount.auxiliaryTypes.map((item: any) => {
            const auxiliaryType: any = {
              auxiliaryTypeId: item.auxiliaryTypeId
            }
            if (item.type === 'ENTITY_ACCOUNT') {
              auxiliaryType.entityAccountId = ''
            } else if (item.type === 'COUNTERPARTY') {
              auxiliaryType.counterpartyId = ''
            } else if (item.type === 'ITEM') {
              auxiliaryType.auxiliaryItemId = ''
            }
            return auxiliaryType
          })
        : []
      if (!isEmpty(hasGstType.value)) {
        onChangeGSTRateType()
      }
      if (
        journalForm.value.brokerInventoryType === 'SALE' &&
        journalForm.value.entryLines[index].balanceType === 'Dr' &&
        chartOfAccount?.type === 'EXPENSE'
      ) {
        journalForm.value.entryLines[index].amount = 'Based on cost basis'
        journalForm.value.entryLines[index].amountFC = ''
      }
    }
    const isGstEntry = chartOfAccount?.slug === 'gst_output' || chartOfAccount?.slug === 'gst_input'
    journalForm.value.entryLines[index].currency =
      // eslint-disable-next-line no-nested-ternary
      chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ? entityDetail.value?.defaultCurrency : isGstEntry ? 'SGD' : ''
    const currencyTypes =
      currentChartOfAccount(journalForm.value.entryLines[index].chartOfAccountId).value?.currencyTypes ?? []
    const params = {
      keywords: chartOfAccount?.currencyTypes?.[0] !== 'REPORTING' ? [] : [entityDetail.value?.defaultCurrency],
      recommend: chartOfAccount?.currencyTypes?.[0] !== 'REPORTING',
      entityId: entityId.value,
      type: currencyTypes
    }
    try {
      const { data } = await ConfigApi.searchCurrencyList(params)
      currencyList[index] = data
      const list = [...cloneDeep(currenciesCommonData.value), ...data]
      currenciesCommonData.value = uniqBy(list, 'symbol')
    } catch (error) {
      currencyList[index] = []
    }
  } else {
    onResetEntry(index)
    currencyList[index] = currenciesRecommendData.value
    if (journalForm.value.entryLines?.length > 2 && originalGstTypeIndex.value === index) {
      const removeEntryLine = (i: number, balanceType: string) => {
        if (journalForm.value.entryLines[i].balanceType === balanceType) {
          journalForm.value.entryLines.splice(i, 1)
        }
      }
      journalForm.value.entryLines.forEach((item: any) => {
        const chartOfAccountVal = currentChartOfAccount(item.chartOfAccountId).value
        if (chartOfAccountVal?.slug === 'gst_output' || chartOfAccountVal?.slug === 'gst_input') {
          removeEntryLine(item.index, item.balanceType)
        }
      })
      journalForm.value.entryLines.forEach((item: any, i: number) => {
        item.index = i
      })
    }
    if (!isGeneralJournalType.value) {
      journalForm.value.entryLines[index].auxiliaryValueList = [
        {
          auxiliaryTypeId: props.auxiliaryAccountTypeId,
          entityAccountId: ''
        }
      ]
    }
  }
}

/**
 * @description: 创建普通日记账
 * @param {string} id
 */
const onCreateGeneralJournal = (id: string) => {
  journalTypeId.value = id
  journalForm.value.brokerInventoryType = 'NONE'
  if (isEmpty(props.currentData) || props.model === 'edit') {
    unref(journalTypeSelectRef)?.hide?.()
    journalForm.value.journalTypeId = id
    onResetEntry()
  }
}

/**
 * @description: 获取日记账预览
 */
const getJournalByTransactionPreview = async () => {
  try {
    previewLoading.value = true
    const params = {
      journalTypeId: journalForm.value.journalTypeId,
      transactionIds: props.currentData.transactionIds,
      journalActivityId: props.currentData.journalActivityId
    }
    const { data } = await TransactionsApi.journalByTransactionPreview(entityId.value, params)
    journalForm.value.entryLines = data.entryLines.map((item: any, i: number) => {
      return {
        index: i,
        balanceType: item.balanceType,
        chartOfAccountId: item.chartOfAccountId,
        currency: item.currency,
        amountOrigin: item.amount,
        changeAmount: true,
        useManualPrice: false,
        amount: formatNumberToSignificantDigits(Number(item.amount), 2, '', false),
        amountFC: item.amountFC,
        gstRatio: item.gstRatio,
        gstType: item.gstType,
        auxiliaryValueList: filterAuxiliaryValueList(item.auxiliaryValueList, item.chartOfAccountId)
      }
    })
    console.log(journalForm.value.entryLines)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    previewLoading.value = false
  }
}

/**
 * @description: 切换JournalType
 */
const onChangeJournalTypeSelect = () => {
  onResetEntry()
  unref(journalTypeSelectRef)?.hide?.()
  journalForm.value.brokerInventoryType = currentJournalTypeData.value?.brokerInventoryType ?? 'NONE'
  const journalType = journalForm.value.journalTypeId
  journalForm.value.journalTypeId = journalType
  if (
    props.model === 'edit' &&
    !isGeneralJournalType.value &&
    props.currentData?.transactionIds?.length &&
    !isCompoundEntry.value
  ) {
    getJournalByTransactionPreview()
    return
  }
  const entryLines = currentJournalTypeData.value?.entryLines.map((item: any, i: number) => {
    const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
    return {
      index: i,
      balanceType: item.balanceType,
      chartOfAccountId:
        item?.chartOfAccountId && item?.chartOfAccountId !== '0' && item.chartOfAccountCalculator !== 'TREASURY_ACCOUNT'
          ? item?.chartOfAccountId
          : '',
      currency: item?.chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ? entityDetail.value?.defaultCurrency : '',
      amount:
        journalForm.value.brokerInventoryType === 'SALE' &&
        item.balanceType === 'Dr' &&
        chartOfAccount?.type === 'EXPENSE'
          ? 'Based on cost basis'
          : (item.amount ?? ''),
      amountFC: item?.amountFC ?? '',
      useManualPrice: false,
      auxiliaryValueList:
        item.chartOfAccountCalculator !== 'TREASURY_ACCOUNT'
          ? item.chartOfAccount?.auxiliaryTypes.map((auxiliary: any) => {
              const auxiliaryTyp: any = {
                auxiliaryTypeId: auxiliary.auxiliaryTypeId
              }
              if (auxiliary.type === 'ENTITY_ACCOUNT') {
                auxiliaryTyp.entityAccountId = ''
              }
              if (item.chartOfAccountCalculator === 'COUNTERPARTY') {
                auxiliaryTyp.counterpartyId = ''
              }
              if (item.chartOfAccountCalculator === 'ITEM') {
                auxiliaryTyp.auxiliaryItemId = ''
              }
              return auxiliaryTyp
            })
          : [
              {
                auxiliaryTypeId: props.auxiliaryAccountTypeId,
                entityAccountId: ''
              }
            ],
      gstType: item?.gstType ?? '',
      gstRatio: item?.gstRatio ? Number(item.gstRatio) : 0
    }
  })
  journalForm.value.entryLines = entryLines
  journalForm.value.entryLines.forEach((item: any) => {
    if (item.chartOfAccountId && currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING')
      return
    onChangeChartOfAccount(item.index)
  })
  if (entityStore.entityDetail?.enableGst && isGeneralJournalType.value) {
    onChangeGSTRateType()
  }
}

/**
 * @description: 更改日期
 */
const onChangeDate = () => {
  if (entityStore.entityDetail?.enableGst && isGeneralJournalType.value) {
    onChangeGSTRateType()
  } else {
    journalForm.value.entryLines.forEach((item: any, index: number) => {
      if (item.amount !== '' && !item?.useManualPrice) {
        onChangeAmountFun(index)
      }
    })
  }
}

/**
 * @description: 切换弹窗展示
 */
const onCheckAddJournalDialog = () => {
  showAddJournalDialog.value = !showAddJournalDialog.value
}

/**
 * @description: 创建Journal
 * @param {*} params
 */
const onCreateJournal = async (params: any) => {
  try {
    const { data } = await LedgerApi.createJournal(entityId.value, params)
    if (data?.length > 0) {
      emit('resetList')
      entityStore.fetchCurrencyList(entityId.value)
      ElMessage.success(t('message.createJournalSuccess'))
    } else {
      ElMessage.error(t('message.createJournalFailed'))
    }
    onCheckAddJournalDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

/**
 * @description: 编辑Journal
 * @param {*} data
 */
const onEditJournal = async (data: any) => {
  try {
    const params = {
      ...cloneDeep(data)
    }
    if (props.currentData?.transactionIds?.length) {
      delete params.transactionIdPairs
      delete params.referenceNo
      delete params.datetimeStr
      delete params.timezone
      if (!isGeneralJournalType.value) {
        delete params.journalTypeGroup
      }
    }
    await LedgerApi.editJournal(entityId.value, props.currentData?.journalActivityId, params)
    emit('resetList')
    entityStore.fetchCurrencyList(entityId.value)
    ElMessage.success(t('message.editSuccess'))
    onCheckAddJournalDialog()
    if (props.tableType === 'journal') {
      emit('resetDetail')
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

/**
 * @description: 保存Journal
 */
const onSaveJournal = async () => {
  if (!journalFormRef.value) return
  if (projectDetail.value?.plan !== ProjectPermissionLevel.OLD_BASIC) {
    brokerInventoryValidate.value = true
  }
  await journalFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        const params: any = {
          ...cloneDeep(journalForm.value),
          datetimeStr: dayjs(journalForm.value.datetimeStr).format('YYYY-MM-DD HH:mm:ss'),
          memo: uploadMemoRef.value?.memo
        }
        params.journalAttachmentIds = map(uploadMemoRef.value?.fileList, 'journalAttachmentId')
        params.entryLines.forEach((item: any, index: number) => {
          item.gstRatio = item.gstType !== 'NONE' && item.gstType !== 'NA' ? item.gstRatio / 100 : 0
          item.auxiliaryValueList = item.auxiliaryValueList.filter(
            (i: any) =>
              (i.counterpartyId !== undefined && i.counterpartyId !== '') ||
              (i.entityAccountId !== undefined && i.entityAccountId !== '') ||
              (i.auxiliaryItemId !== undefined && i.auxiliaryItemId !== '')
          )
          item.auxiliaryValueList = item.auxiliaryValueList.map((auxiliary: any) => {
            return pick(auxiliary, ['auxiliaryItemId', 'entityAccountId', 'counterpartyId', 'auxiliaryTypeId'])
          })
          item.amount = item?.amountOrigin ? Number(item?.amountOrigin) : Number(item.amount)
          item.amountFC = item?.amountFC !== '' ? Number(item?.amountFC) : ''
          delete item.index
          delete item.amountOrigin
          delete item.changeAmount
          if (
            !isGeneralJournalType.value &&
            currentJournalTypeData.value?.entryLines[index]?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'
          ) {
            const chartOfAccount = currentChartOfAccount(item.chartOfAccountId).value
            if (chartOfAccount && !chartOfAccount?.auxiliaryTypes?.length) item.auxiliaryValueList = []
          }

          if (
            journalForm.value.brokerInventoryType === 'SALE' &&
            isGeneralJournalType.value &&
            item.balanceType === 'Dr'
          ) {
            item.amount = ''
            item.amountFC = ''
          }
        })
        if (!isEmpty(props.currentData)) {
          params.transactionIdPairs = [{ transactionIds: [props.currentData.transactionId] }]
        }
        if (!isGeneralJournalType.value) {
          delete params.brokerInventoryType
        }
        delete params.journalForm
        if (props.model === 'add') {
          await onCreateJournal(params)
        } else {
          await onEditJournal(params)
        }
      } catch (error: any) {
        console.log(error)
      }
    }
  })
}

// 关闭弹窗
const onCloseDialog = () => {
  journalFormRef.value?.resetFields()
  uploadMemoRef.value?.resetMemoData()
  brokerInventoryValidate.value = false
  brokerInventoryError.value = ''
  journalForm.value = {
    journalTypeId: '',
    transactionIdPairs: [],
    brokerInventoryType: 'NONE',
    journalTypeGroup: 'OPERATING',
    timezone: entityStore.entityDetail?.timezone,
    datetimeStr: '',
    referenceNo: '',
    entryLines: [
      {
        index: 0,
        balanceType: 'Dr',
        chartOfAccountId: '',
        currency: '',
        amount: '',
        amountFC: '',
        changeAmount: false,
        useManualPrice: false,
        gstRatio: 9,
        gstType: 'NONE',
        auxiliaryValueList: []
      },
      {
        index: 1,
        balanceType: 'Cr',
        chartOfAccountId: '',
        currency: '',
        amount: '',
        amountFC: '',
        changeAmount: false,
        useManualPrice: false,
        gstRatio: 9,
        gstType: 'NONE',
        auxiliaryValueList: []
      }
    ],
    memo: '',
    journalAttachmentIds: []
  }
}

defineExpose({ onCheckAddJournalDialog })

watch(
  () => showAddJournalDialog.value,
  async () => {
    if (showAddJournalDialog.value) {
      journalTypeId.value = ''
      try {
        searchLoading.value = true
        const params = {
          limit: 20,
          page: 1
        }
        const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
        originCounterpartyOptions.value = data.list
        counterpartyOptions.value = data.list
      } catch (error) {
        console.log(error)
      } finally {
        searchLoading.value = false
      }
    }
  },
  {
    immediate: true
  }
)

watch([() => showAddJournalDialog.value, () => props.currentData, () => props.selectedJournalTypeId], async () => {
  if (showAddJournalDialog.value && !isEmpty(props.currentData)) {
    journalForm.value.journalTypeId =
      props.currentData?.journalTypeId &&
      (props.currentData?.journalActivityList?.length || props.currentData?.journalType?.journalTypeId)
        ? props.currentData?.journalTypeId
        : (props.selectedJournalTypeId ?? '')
    journalForm.value.datetimeStr = dayjs(props.currentData?.datetime)
      .tz(props.currentData?.timezone ? props.currentData.timezone : props.currentData?.source?.timezone)
      .format('YYYY/MM/DD HH:mm')
    journalForm.value.timezone = props.currentData?.timezone
      ? props.currentData.timezone
      : (props.currentData?.source?.timezone ?? 'UTC')
    journalForm.value.brokerInventoryType = props.currentData?.brokerInventoryType ?? 'NONE'
    journalForm.value.referenceNo = props.currentData?.referenceNo
      ? props.currentData.referenceNo
      : (props.currentData?.txHash ?? '')
    journalForm.value.journalTypeGroup = props.currentData?.journalTypeGroup ?? 'OPERATING'
    if (props.model === 'edit' && props.selectedJournalTypeId && props.isRecategorize) {
      journalForm.value.journalTypeId = props.selectedJournalTypeId
    }
    if (props.model === 'edit' || (props.model === 'add' && isCompoundEntry.value)) {
      if (props.currentData?.entryList) {
        const entryList = sortBy(props.currentData?.entryList, (i: any) => {
          if (i.balanceType === 'Dr') {
            return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 1 : 0
          }
          if (i.balanceType === 'Cr') {
            return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 3 : 2
          }
          return 0
        })
        journalForm.value.entryLines = entryList?.map((item: any, i: number) => {
          const chartOfAccount = currentChartOfAccount(item?.chartOfAccountId).value
          const entry = {
            index: i,
            balanceType: item.balanceType,
            chartOfAccountId: item.chartOfAccountId,
            currency: item.currency,
            changeAmount: true,
            useManualPrice: item?.useManualPrice ?? false,
            amount:
              journalForm.value.brokerInventoryType === 'SALE' &&
              item.balanceType === 'Dr' &&
              chartOfAccount?.type === 'EXPENSE'
                ? 'Based on cost basis'
                : (item.amount ?? '0'),
            amountFC: item.amountFC,
            gstRatio: item.gstRatio ? Number(item.gstRatio * 100) : 0,
            gstType: item.gstType,
            auxiliaryValueList: filterAuxiliaryValueList(item.auxiliaryValueList, item.chartOfAccountId)
          }
          if (chartOfAccount?.auxiliaryTypes?.length) {
            const auxiliaryList = map(chartOfAccount.auxiliaryTypes, (auxiliaryItem: any) => {
              const auxiliary: any = {
                auxiliaryTypeId: auxiliaryItem.auxiliaryTypeId
              }
              if (auxiliaryItem.type === 'ENTITY_ACCOUNT') {
                auxiliary.entityAccountId = ''
              } else if (auxiliaryItem.type === 'COUNTERPARTY') {
                auxiliary.counterpartyId = ''
              } else if (auxiliaryItem.type === 'ITEM') {
                auxiliary.auxiliaryItemId = ''
              }
              return auxiliary
            })
            entry.auxiliaryValueList = auxiliaryList.map((auxiliaryItem: any) => {
              const auxiliary = find(filterAuxiliaryValueList(item.auxiliaryValueList, item.chartOfAccountId), {
                auxiliaryTypeId: auxiliaryItem?.auxiliaryTypeId
              })
              return auxiliary ? { ...auxiliaryItem, ...auxiliary } : auxiliaryItem
            })
          }
          return entry
        })
        const currencyData = map(entryList, (item: any) => item?.underlyingCurrency)
        const list = [...cloneDeep(currenciesCommonData.value), ...currencyData]
        currenciesCommonData.value = uniqBy(list, 'symbol')
        currenciesData.value = cloneDeep(currenciesCommonData.value)
      } else if ((props.model === 'add' || (props.model === 'edit' && props.isRecategorize)) && isCompoundEntry.value) {
        onChangeJournalTypeSelect()
        journalForm.value.brokerInventoryType = currentJournalTypeData.value?.brokerInventoryType ?? 'NONE'
      }
    }
  }
  if (showAddJournalDialog.value && isEmpty(props.currentData)) {
    journalForm.value.datetimeStr = dayjs().format('YYYY/MM/DD HH:mm')
  }
})

onMounted(async () => {
  try {
    const params = {
      recommend: true,
      entityId: 0
    }
    const { data } = await ConfigApi.searchCurrencyList(params)
    currenciesRecommendData.value = data
    const list = [entityDetail.value?.underlyingCurrency, ...cloneDeep(currenciesCommonData.value), ...data]
    currenciesCommonData.value = uniqBy(list, 'symbol')
  } catch (error) {
    console.log(error)
  }
})
</script>

<style lang="scss">
.elv-add-journal-dialog {
  width: 700px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-add-journal-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 0 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-form-item__label {
      &::before {
        // 隐藏掉必填的小红星
        display: none;
      }
    }

    .elv-add-journal-content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;

      .elv-add-journal-transaction-info {
        display: flex;
        width: 652px;
        height: 42px;
        align-items: center;
        gap: -1px;
        align-self: stretch;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid #dde1e6;
        background: #fff;
        margin-bottom: 16px;

        .elv-add-journal-transaction-info__type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 100%;
          padding: 0 10px;
          box-sizing: border-box;
          border-radius: 4px 0px 0px 4px;
        }

        .elv-add-journal-transaction-info__identity {
          display: flex;
          height: 100%;
          width: 309px;
          flex-direction: column;
          padding: 0px 10px;
          box-sizing: border-box;
          align-items: flex-start;
          align-self: stretch;
          justify-content: center;
          border-left: 1px solid #edf0f3;
          border-right: 1px solid #edf0f3;

          .elv-add-journal-transaction-info__identity-top {
            font-family: 'Barlow';
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: $elv-theme-base-text-color;
          }

          .elv-add-journal-transaction-info__identity-bottom {
            display: flex;
            align-items: center;
            cursor: pointer;

            .elv-add-journal-transaction-info__identity-bottom__txHash {
              height: 16px;
              font-family: 'Barlow';
              font-weight: 400;
              font-size: 12px;
              zoom: 0.83;
              -moz-transform: scale(0.83);
              -moz-transform-origin: top left;
              line-height: 16px;
              color: #6b7177;
            }

            .elv-add-journal-transaction-info__identity-bottom__copy {
              padding-left: 4px;
              width: 10px;
              height: 10px;
            }
          }
        }

        .elv-add-journal-transaction-info__amount {
          height: 100%;
          width: 309px;
          padding: 0px 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .elv-add-journal-transaction-info__deleted {
          display: flex;
          padding: 0px 10px;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          box-sizing: border-box;
          color: #aaafb6;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-left: 1px solid #edf0f3;
        }
      }

      .elv-add-journal-type-select {
        box-sizing: border-box;
        height: 44px;
        width: 652px;
        padding: 10px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-disabled {
          border: 1px solid #dde1e6;
          background-color: #f9fafb;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
          cursor: not-allowed;
        }

        &.is-short {
          width: 432px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        .is-placeholder {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        &:not(.is-disabled):hover {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 14px;
          fill: #838d95;
          transition: transform 0.3s;
        }
      }
    }

    .elv-add-journal-type-form-item__entry-add {
      margin-bottom: 16px;

      svg {
        margin-right: 4px;
        fill: #838d95;
      }

      &:not(.is-disabled) {
        color: #1753eb !important;

        svg {
          fill: #1753eb;
          margin-right: 4px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      .el-select,
      .el-input {
        &.is-shorter {
          width: 212px;
        }

        &.is-shorter-entry {
          width: 206px;
        }
      }

      .el-select .el-select__wrapper.is-disabled {
        .el-select__selected-item {
          color: #0e0f11;
        }
      }

      .el-input {
        height: 44px;

        &.el-date-editor .el-input__wrapper {
          flex-direction: row-reverse;

          .el-icon.el-input__icon {
            margin-right: 0px;
          }
        }

        &.is-disabled .el-input__inner {
          color: #0e0f11;
          -webkit-text-fill-color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      .el-form-item__label {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .elv-transactions-add-journal-form-item__multiple {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:first-of-type {
        margin-bottom: 16px;
      }
    }

    .elv-transactions-add-journal-form-item__memo {
      .elv-report-journal-drawer-memo-upload .el-upload-dragger {
        width: 636px;
      }
    }

    .elv-transactions-add-journal-form-item__broker {
      .el-form-item {
        padding: 8px;
        border-radius: 5px;
        background: #f9fafb;

        .elv-transactions-add-journal-form-item__broker-error {
          color: #df2200;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 8px;
        }
      }
    }

    .elv-transactions-add-journal-form-item-title {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 524px;
        height: 1px;
        background: #edf0f3;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
      }

      &.entryLines::after {
        width: 583px;
      }
    }

    .elv-transactions-add-journal-form-item__entry-list {
      margin-bottom: 16px;
    }

    .elv-transactions-add-journal-form-entry-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding: 8px;
      box-sizing: border-box;
      width: 652px;
      gap: 8px;
      border-radius: 5px;
      background: #fffaf9;
      margin-bottom: 16px;

      &.Dr {
        background: #f5f8ff;

        .elv-transactions-add-journal-form-entry-item__title.special {
          .elv-transactions-add-journal-form-entry-item__title-balance {
            color: #2368f2;
            background-color: #e2eafc;
          }
        }
      }

      .elv-transactions-add-journal-form-entry-item__title {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid #edf0f3;

        &.general-journal {
          width: 600px;
          position: relative;

          .elv-transactions-add-journal-form-entry-item__delete {
            width: 36px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -36px;
            top: 0;

            svg {
              &:not(.is-disabled):hover {
                cursor: pointer;
                fill: #1753eb;
              }

              &.is-disabled {
                fill: #d0d4d9;
                cursor: not-allowed;
              }
            }
          }

          .el-form-item {
            margin-bottom: 0px;
          }

          .elv-journal-coa-dropdown-content {
            width: 510px;
          }

          .elv-transactions-add-journal-form-entry-item__title-balance .el-select__wrapper {
            display: flex;
            width: 80px;
            height: 44px;
            padding: 10px 12px;
            box-sizing: border-box;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            align-self: stretch;
            margin-right: 10px;
            color: #0e0f11;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
          }
        }

        &.special {
          width: 100%;

          .elv-transactions-add-journal-form-entry-item__title-balance {
            display: flex;
            padding: 2px 4px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #f24923;
            border-radius: 2px;
            background: #feede9;
            margin-right: 10px;
          }

          .elv-transactions-add-journal-form-entry-item__title-coa {
            color: #0e0f11;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            div {
              display: flex;
              align-items: center;
            }

            &.is-Empty {
              font-weight: 400;
              color: #aaafb6;
            }

            p {
              cursor: pointer;
              display: flex;
              color: #5e85eb;
              font-family: 'Plus Jakarta Sans';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              svg {
                margin-right: 4px;
              }
            }

            .el-loading-mask {
              // background-color: rgba(245, 248, 255, 0.8);
              background-color: transparent;
            }

            .el-loading-spinner {
              top: 1px;
              right: -26px;
              justify-content: flex-end !important;

              margin-top: 0px;
            }

            .circular {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .elv-transactions-add-journal-form-entry-item__multiple {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .elv-transactions-add-journal-form-entry-item__GSTRate {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &.general-journal {
          .el-form-item {
            .el-input.is-shorter-entry {
              width: 194.67px;
            }
          }
        }

        .el-form-item {
          margin-bottom: 0px;

          &:last-child {
            margin-right: 0px;
          }

          .el-form-item__label {
            height: 16px;
          }

          .el-select__wrapper .el-select__prefix {
            gap: 10px;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              display: block;
            }
          }

          &.elv-transactions-add-journal-form-entry-item__multiple-amountFC {
            position: relative;

            .elv-add-journal-entry-item__multiple-amountFC-content {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-right: 12px;
              padding-left: 0px;
              position: absolute;
              right: 1px;
              height: 28px;

              .elv-add-journal-entry-item__multiple-amountFC-auto_calculate {
                color: #838d95;
                text-align: right;
                font-family: 'Barlow';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;

                .el-loading-mask {
                  right: 1px;
                }

                .el-loading-spinner {
                  margin-top: 0px;
                  top: 0px;
                  right: 11px;
                  text-align: right;
                }
              }

              .circular {
                width: 20px;
                height: 20px;
              }

              .elv-add-journal-entry-item__multiple-amountFC-manually.el-input {
                height: 28px;
                width: 90px;

                .el-input__wrapper {
                  padding: 0;
                  border: 0;
                  box-shadow: none;

                  .el-input__inner {
                    color: #838d95;
                    text-align: right;
                    font-family: 'Barlow';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                  }
                }
              }
            }

            &.is-sale-disabled {
              .el-input__wrapper .el-input__inner {
                color: #838d95;
                -webkit-text-fill-color: #838d95;
                font-family: 'Plus Jakarta Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
              }
            }
          }

          &.elv-transactions-add-journal-form-entry-item__multiple-percent {
            position: relative;

            .el-input-number.is-without-controls .el-input__wrapper {
              padding: 0 12px;
            }

            .el-input-number {
              width: 90px;

              .el-input__inner {
                text-align: left;
              }
            }

            .symbol {
              padding-right: 12px;
              padding-left: 0px;
              color: #838d95;
              text-align: right;
              font-family: 'Barlow';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
              height: 28px;
              position: absolute;
              right: 1px;
              bottom: 10px;
            }
          }

          &.elv-transactions-add-journal-form-entry-item__multiple-type {
            .el-select__wrapper {
              width: 265px;
            }
          }
        }
      }
    }

    .elv-transactions-add-journal-form-entry-check {
      display: flex;
      min-width: 290px;
      width: fit-content;
      height: 45px;
      padding: 8px 12px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 5px;
      background: #35c66f;
      margin: 0 auto;

      div {
        display: flex;
        padding: 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color: #fff;
        font-family: 'Barlow';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        p {
          display: flex;
          padding: 2px 3px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 2px;
          border: 1px solid #fff;
          margin-right: 4px;
        }
      }

      span {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Barlow';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &.is-error {
        background: #eb5a52;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.el-popper.elv-add-journal-amount-tips {
  display: flex;
  padding: 5px 10px 8px 10px;
  box-sizing: border-box;
  width: 190px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.el-popper.elv-add-journal-type-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}

.el-popper.el-select__popper {
  .elv-transactions-add-journal-form-account-item__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 24px 0;

    p {
      color: #aaafb6;
      margin-top: 2px;
      margin-bottom: 12px;
    }

    a {
      color: #1753eb;
      cursor: pointer;
    }
  }
}

.elv-add-journal-type__dropdown-popper {
  overflow: hidden;
  transform: translateY(-11.5px);

  .elv-add-journal-type__dropdown {
    padding: 0;

    .el-dropdown-menu__item {
      box-sizing: border-box;
      width: 140px;
      height: 32px;
      background: #ffffff;
      padding: 0px 8px;
      font-size: 12px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      justify-content: flex-start;
      align-items: center;

      &.is-disabled {
        background-color: #f9fafb;
      }
    }

    .el-dropdown-menu__item:not(.is-disabled):focus {
      background-color: $elv-color-F9FBFE;
      color: $elv-color-0E1420;
    }
  }
}

.el-popper.elv-add-journal-date-popper {
  transform: translateY(-11.5px);
  border: 1px solid #e4e7eb;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  // overflow: hidden;
  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;

      .el-date-picker__time-header {
        padding: 0px;
        padding-bottom: 8px;

        .el-input {
          height: 28px;

          .el-input__inner {
            color: #0e0f11;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }

      .el-time-panel {
        left: -75px;
      }
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }
}
</style>
